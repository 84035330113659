<template>
  <div style="height: 1000px; width: 100%" v-if="role === 'ADMIN'">
    <l-map
        :zoom="zoom"
        :center="[42.850, 74.621111]"
        @click="addPoint"
        @update:zoom="zoomUpdate">
      <l-tile-layer :url="url"/>

      <l-control class="example-custom-control">
        <v-container fluid>
          <v-checkbox v-for="z in zones"
                      :key="z.id"
                      v-model="z.edit"
                      :label="`${z.name}`"/>
          <v-btn v-if="edited"
                 color="primary"
                 icon
                 @click="save">
            <v-icon large>mdi-check-bold</v-icon>
          </v-btn>
        </v-container>
      </l-control>

      <l-polygon v-for="z in zones" :key="z.name" :lat-lngs="z.polygon">
      </l-polygon>

      <template v-for="z in editZones">
        <l-marker v-for="p in z.polygon"
                  :key="p.id"
                  :lat-lng="p"
                  :draggable=true
                  @dragend="dragend"
                  @dragstart="dragstart(p)">
          <l-popup>
            <v-icon small
                    color="error"
                    class="mr-2"
                    @click="remove(z, p)">
              mdi-delete
            </v-icon>
          </l-popup>
          <l-tooltip :options="{ permanent: true, interactive: true }">
            <div>
              {{ p.priority }}
            </div>
          </l-tooltip>
        </l-marker>
      </template>
    </l-map>
  </div>
</template>

<script>
import {LControl, LMap, LMarker, LPolygon, LPopup, LTileLayer, LTooltip} from "vue2-leaflet";
import axios from "@/services/axios";

export default {
  components: {
    LMap,
    LTileLayer,
    LPolygon,
    LMarker,
    LControl,
    LPopup,
    LTooltip
  },
  data() {
    return {
      zoom: 13,
      currentZoom: 13,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      point_id: '',
      zones: [],
      edited: false
    };
  },

  computed: {
    role() {
      return this.$store.getters["auth/role"];
    },
    size() {
      return ((this.currentZoom - this.zoom) > 1 ? (this.currentZoom - this.zoom) : 1) * 24;
    },
    editZones() {
      return this.zones.filter(z => z.edit)
    },
  },

  mounted() {
    axios
        .send({
          url: "/zones",
          method: "GET",
        })
        .then((resp) => {
          this.zones = resp.data;
        });
  },

  methods: {
    remove(z, p) {
      axios
          .send({
            url: "/zones/" + p.id,
            method: "DELETE",
          })
          .then(() => {
            z.polygon = z.polygon.filter(point => p.id !== point.id)
          });
    },
    save() {
      let points = [];
      for (let zone of this.zones) {
        for (let p of zone.polygon) {
          if (p.edit) {
            points.push(p);
            delete p.edit
          }
        }
      }

      axios
          .send({
            url: "/zones",
            method: "PUT",
            data: points,
          })
          .then(() => {
            this.edited = false;
          });
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    addPoint(event) {
      axios
          .send({
            url: "/zones/check",
            method: "POST",
            data: {lat: event.latlng.lat, lng: event.latlng.lng},
          })
          .then((resp) => {
            alert(resp.data)
          });
    },
    dragend(event) {
      for (let z of this.zones) {
        if (z.edit) {
          for (let p of z.polygon) {
            if (p.id === this.point_id) {
              p.lat = event.target._latlng.lat;
              p.lng = event.target._latlng.lng;
              p.edit = true
              this.point_id = ''
            }
          }
        }
      }
      this.edited = true;
    },
    dragstart(point) {
      this.point_id = point.id;
    },
  }
};
</script>

<style>
.example-custom-control {
  background: #fff;
  padding: 0 0.5em;
  border: 1px solid #aaa;
  border-radius: 0.1em;
}
</style>
